import React from "react"
import Layout from "../../components/layoutEn"
import SEO from "../../components/layout/seo"
import MutipleSlidesPerView from "../../components/standProj/CoverflowEffect"

import standProjMain from "../../img/standProjMain.png"

const GroupStand = () => (
  <Layout>
    <SEO title="M2MTech" />
    <div className="keicStandWrap">
      <div className="sectionWrap StandOverview">
        <p className="standProjMain">
          <img src={standProjMain} alt="" />
        </p>
        <div className="textWrap">
          <h2>
            KEIC <span>standard</span>
          </h2>
          <p className="bodyText">
            M2MTech Co., Ltd. was adopted as the standard for smart remote care
            system in the “Electric Elevator Manufacturing and Assembly Quality
            Standard” of the Korea Elevator Industry Cooperative established in
            2017, and updated to the latest in 2020. Install the latest IoT
            (Internet of Things)-based smart IoT devices for the safety of
            elevator users and system maintenance, 24 hours a day 365 days a
            year through the Internet without restrictions on time and place. It
            is the strength of the Elsa system that makes it possible.
          </p>
        </div>
      </div>
      <div className="sectionWrap standServices">
        <div className="textWrap">
          <h2>
            SPS Electric Elevator Manufacturing and Assembly Quality Standard
          </h2>
        </div>
        <ul>
          <li>
            System configuration (hardware and software) and features
            <span>
              The configuration of the smart remote care system consists of an
              IoT device (elevator control signal transmitter) that connects the
              elevator and the elevator subject to inspection/diagnosis with the
              cloud center, and a cloud that comprehensively monitors,
              diagnoses, and controls the elevator operation status at each
              site. made up of centers The administrator accesses it through a
              web browser using various terminal devices such as smartphones,
              tablets, and PCs.
            </span>
          </li>
          <li>
            elevator control panel
            <span>
              Communication between the elevator control panel and IoT devices
              uses the standard protocol (standard protocol for the elevator
              joint model) that has been eliminated by the Korea Elevator
              Industry Cooperative Standard (SPS-KEIC-001:2017), and is
              physically CAN or RS-485 serial communication is supported to
              connect and communicate with IoT devices.
            </span>
          </li>
          <li>
            IoT device
            <span>
              The IoT device acts as a gateway between the elevator control
              panel and the cloud center, and is installed and managed
              one-to-one with the elevator in order to reliably and
              independently perform the elevator operation record and data
              storage and diagnosis/control functions.
            </span>
          </li>
          <li>
            cloud center
            <span>
              The cloud center utilizes Internet technology to secure work
              flexibility, scalability, and stability through the advantages of
              efficiency and cost reduction of the elevator work system. Above
              all, users (including engineers and management subjects) should be
              able to easily use the elevator maintenance work anytime, anywhere
              through various terminals through the cloud center.
            </span>
          </li>
        </ul>
      </div>
      <div className="sectionWrap standGallery">
        <div className="">
          <MutipleSlidesPerView />
        </div>
      </div>
    </div>
  </Layout>
)

export default GroupStand
